import { DOCUMENT } from "@angular/common";
import { Component, Inject } from "@angular/core";
import { CommonService } from "@services/common/common.service";
import { LocalizationService } from "@services/global/localization.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent {
  title = "angular-admin";
  themeMode: string = "";
  isCheckedJionex: boolean = false;
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private commonService: CommonService,
    private localizationService: LocalizationService
  ) {
    this.themeMode = localStorage.getItem("pay2LocalThemeMode") || "";
    if (this.themeMode) {
      this.loadStyle(this.themeMode);
    } else {
      this.loadStyle("light-style");
    }
  }
  ngOnInit() {
    this.commonService.getThemeMode().subscribe((res) => {
      localStorage.setItem("pay2LocalThemeMode", res.type);
      this.loadStyle(res?.type);
      this.themeMode = res.type;
    });
    this.getJionexStatus();
    //this.localizationService.setLanguage("bn");
  }
  loadStyle(styleName: string) {
    const head = this.document.getElementsByTagName("head")[0];

    let themeLink = this.document.getElementById(
      "client-theme"
    ) as HTMLLinkElement;
    if (themeLink) {
      themeLink.href = `assets/css/${styleName}.css`;
    } else {
      const style = this.document.createElement("link");
      style.id = "client-theme";
      style.rel = "stylesheet";
      style.type = "text/css";
      style.href = `assets/css/${styleName}.css`;

      head.appendChild(style);
    }
  }
  getJionexStatus() {
    this.commonService.getJionexStatus().subscribe((res: any) => {
      this.isCheckedJionex = res?.jionex;
    });
  }
}
